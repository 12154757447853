import bugsnag from '@bugsnag/browser';
import bugsnagReact from '@bugsnag/plugin-react';
import React from 'react';
import { version } from '../../package.json';

try {
  // generates a random number to distinguish errors from different user sessions
  if (!sessionStorage.getItem('random')) {
    sessionStorage.setItem('random', Math.floor(Math.random() * 1000000).toString());
  }
} catch (e) { /* errors are ignored */ }

export const bugsnagClient = bugsnag({
  apiKey: process.env.REACT_APP_BUGSNAG || 'disabled',
  appVersion: version,
  releaseStage: process.env.REACT_APP_ENVIRONMENT || process.env.NODE_ENV,
  notifyReleaseStages: ['production', 'testing'],
  collectUserIp: false,
  autoCaptureSessions: false,
  consoleBreadcrumbsEnabled: false,
  filters: [
    /password/i,
    /token/i,
    /note/i,
    /targetText/i, // prevents input values being reported in breadcrumbs
    /from/i, // prevents access tokens potentially being reported in navigation breadcrumbs
  ],
  beforeSend: report => {
    // don't notify if no key has been configured
    if (!process.env.REACT_APP_BUGSNAG) report.ignore();

    try {
      report.user = { // eslint-disable-line no-param-reassign
        id: localStorage.getItem('tester') || sessionStorage.getItem('random') || '[UNDEFINED]',
      };
    } catch (e) { /* errors are ignored */ }
  },
});
bugsnagClient.use(bugsnagReact, React);

/**
 * Will report all unhandled errors within its boundary to Bugsnag
 */
const Bugsnag = bugsnagClient.getPlugin('react');
export default Bugsnag;
