import React, { memo } from 'react';
import Month from '../month/month';
import './year.scss';

interface YearProps {
  /** Which year is being displayed */
  year: number;

  /** Called whenever a day is being clicked */
  onDayUpdated: (year: number) => void;
}


/**
 * Renders all months of a year
 */
const Year: React.FC<YearProps> = ({ year, onDayUpdated }) => {
  const renderMonths: JSX.Element[] = [];
  for (let i = 0; i < 12; i++) {
    renderMonths.push(
      <Month
        key={`${year}-${i}`}
        date={new Date(year, i)}
        onDayUpdated={() => onDayUpdated(year)}
      />,
    );
  }

  return <div className="year">{renderMonths}</div>;
};

export default memo(Year);
