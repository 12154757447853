import { CloudAuthenticationError, CloudRateLimitError } from '../types/errors';
import SupportedClouds from '../types/supported-clouds';
import CloudStorage from './cloud';

/**
 * Cloud adapter for OneDrive - should only be used by the StorageHandler
 */
export default abstract class CloudOneDrive extends CloudStorage {
  static readonly variant = SupportedClouds.OneDrive;
  static readonly api = {
    id: process.env.REACT_APP_CLOUD_ONEDRIVE,
    scopes: ['Files.ReadWrite.AppFolder'],
    url: 'https://graph.microsoft.com/v1.0/drive/special/approot',
  };

  /**
   * Loads the access token, if possible. Otherwise, authentication will be triggered
   */
  static init(): void {
    super.init(`https://login.microsoftonline.com/common/oauth2/v2.0/authorize?\
      client_id=${this.api.id}&\
      response_type=token&\
      scope=${this.api.scopes.join(' ')}&\
      redirect_uri=${this.appUrl}`);
  }

  /**
   * Writes the passed value into a file with the specified filename. If the file already exists,
   * it will be overwritten
   */
  static async save(filename: string, value: string): Promise<void> {
    if (!this.token) this.init();

    const response = await fetch(`${this.api.url}:/${filename}:/content`, {
      method: 'PUT',
      headers: [['Authorization', `Bearer ${this.token}`]],
      body: value,
    });

    if (response.status === 401) throw new CloudAuthenticationError();
    if (response.status === 429) throw new CloudRateLimitError();
    if (!response.ok) throw new Error(await response.text());
  }

  /**
   * Loads the content of the specified file
   */
  static async load(filename: string): Promise<string | null> {
    if (!this.token) this.init();

    const response = await fetch(`${this.api.url}:/${filename}:/content`, {
      headers: [['Authorization', `Bearer ${this.token}`]],
    });

    if (response.status === 401) throw new CloudAuthenticationError();
    if (response.status === 429) throw new CloudRateLimitError();
    if (!response.ok && response.status === 404) return null; // ignoring 404
    if (!response.ok) throw new Error(await response.text());

    return response.text();
  }

  /**
   * Returns a list of keys (max 2000) that exist on the storage
   */
  static async list(): Promise<string[]> {
    if (!this.token) this.init();

    const response = await fetch(`${this.api.url}/children?$top=2000`, {
      headers: [['Authorization', `Bearer ${this.token}`]],
    });
    const body = JSON.parse(await response.text());
    if (response.status === 401) throw new CloudAuthenticationError();
    if (response.status === 429) throw new CloudRateLimitError();
    if (!response.ok) throw new Error(body);

    const keys: string[] = [];
    body.value.forEach((v: {name: string}) => keys.push(v.name));
    return keys;
  }

  static disconnect() { super.disconnect(); }
}
