import generateRandomData from '../helper/data';
import StorageHandler from '../storage/storage-handler';
import IDay from '../types/day';
import IYear from '../types/year';

/**
 * Handles the saving & loading of all diary related data
 */
export default abstract class DataService {
  static data: {[key: number]: IYear} = [];

  /**
   * Load all data of the specified year from the storage
   */
  static async loadYear(year: number): Promise<void> {
    if (this.data[year]) return;

    // use random generated data, if the 'randomData' flag is set (intended for test/demo use)
    if (localStorage.getItem('randomData')) {
      this.data[year] = generateRandomData();
      return;
    }

    const response = await StorageHandler.load(year.toString());
    if (response) {
      this.data[year] = JSON.parse(response);
    }
  }

  /**
   * Saves the specified year to the storage
   */
  static async saveYear(year: number): Promise<void> {
    return StorageHandler.save(year.toString(), JSON.stringify(this.data[year]));
  }

  /**
   * Get the data for the specified day. Returns NULL if no data could be found.
   * Note that you have to call loadYear() before using this, otherwise the data will not be found,
   * even tough it might be present on the storage!
   */
  static getDay(date: Date): IDay | null {
    const [year, month, day] = [date.getFullYear(), date.getMonth(), date.getDate()];

    if (!this.data[year] || !this.data[year][month] || !this.data[year][month][day]) return null;
    return this.data[year][month][day];
  }

  /**
   * Set the data for the specified day
   */
  static setDay(date: Date, values: IDay): void {
    const [year, month, day] = [date.getFullYear(), date.getMonth(), date.getDate()];

    if (!this.data[year]) this.data[year] = {};
    if (!this.data[year][month]) this.data[year][month] = {};
    this.data[year][month][day] = values;
  }

  /**
   * Discards all cached diary data
   */
  static clearCache(): void {
    this.data = [];
  }
}
