/**
 * Should be thrown if any operation of a cloud storage fails due to insufficient permissions
 */
export class CloudAuthenticationError extends Error {
  constructor() {
    super();
    Object.setPrototypeOf(this, CloudAuthenticationError.prototype);
  }
}

/**
 * Should be thrown if any operation of a cloud storage fails due to rate limits (status 429)
 */
export class CloudRateLimitError extends Error {
  /** Time in seconds until the operation should be tried again */
  public readonly retryAfter = 1 + Math.random();

  constructor() {
    super();
    Object.setPrototypeOf(this, CloudRateLimitError.prototype);
  }
}

/**
 * Should be thrown if the initialization of a cloud adapter failed
 */
export class CloudInitError extends Error {
  constructor() {
    super();
    Object.setPrototypeOf(this, CloudInitError.prototype);
  }
}

/**
 * Should be thrown if the data transfer failed
 */
export class CloudTransferError extends Error {
  constructor() {
    super();
    Object.setPrototypeOf(this, CloudTransferError.prototype);
  }
}

/**
 * Should be thrown if any encryption related operation fails
 */
export class CryptoError extends Error {
  constructor() {
    super();
    Object.setPrototypeOf(this, CryptoError.prototype);
  }
}

/**
 * Should be thrown if any operation of the localStorage fails
 */
export class LocalStorageError extends Error {
  constructor() {
    super();
    Object.setPrototypeOf(this, LocalStorageError.prototype);
  }
}
