import { CloudAuthenticationError, CloudRateLimitError } from '../types/errors';
import SupportedClouds from '../types/supported-clouds';
import CloudStorage from './cloud';

/**
 * Cloud adapter for Google Drive - should only be used by the StorageHandler
 */
export default abstract class CloudGoogleDrive extends CloudStorage {
  static readonly variant = SupportedClouds.GoogleDrive;
  static readonly api = {
    id: process.env.REACT_APP_CLOUD_GOOGLE_DRIVE,
    scopes: ['https://www.googleapis.com/auth/drive.appdata'],
    readUrl: 'https://www.googleapis.com/drive/v3/files',
    writeUrl: 'https://www.googleapis.com/upload/drive/v3/files',
  };

  /**
   * Loads the access token, if possible. Otherwise, authentication will be triggered
   */
  static init(): void {
    super.init(`https://accounts.google.com/o/oauth2/v2/auth?\
      client_id=${this.api.id}&\
      response_type=token&\
      scope=${encodeURI(this.api.scopes.join(' '))}&\
      redirect_uri=${this.appUrl.replace(/\/$/, '')}` // Google doesn't allow trailing slashes
      .replace(/ /gm, '')); // removing white spaces from the string, otherwise Google is confused
  }

  /**
   * Writes the passed value into a file with the specified filename. If the file already exists,
   * it will be overwritten
   */
  static async save(filename: string, value: string): Promise<void> {
    if (!this.token) this.init();

    const fileId = await this.exists(filename);
    const content = new FormData();
    content.append('meta', new Blob([JSON.stringify({
      name: filename,
      parents: !fileId ? ['appDataFolder'] : undefined, // omit parents when updating a file
    })], { type: 'application/json' }));
    content.append('file', new Blob([value], { type: 'text/plain' }));

    const response = await fetch(`${this.api.writeUrl}${fileId ? `/${fileId}` : ''}?uploadType=multipart`, {
      method: fileId ? 'PATCH' : 'POST',
      headers: [['Authorization', `Bearer ${this.token}`]],
      body: content,
    });

    if (response.status === 401) throw new CloudAuthenticationError();
    if (response.status === 429) throw new CloudRateLimitError();
    if (!response.ok) throw new Error(await response.text());
  }

  /**
   * Loads the content of the specified file
   */
  static async load(filename: string): Promise<string | null> {
    if (!this.token) this.init();

    const fileId = await this.exists(filename);
    if (!fileId) return null; // missing files are ignored

    const file = await fetch(`${this.api.readUrl}/${fileId}?alt=media`, {
      headers: [['Authorization', `Bearer ${this.token}`]],
    });

    if (file.status === 401) throw new CloudAuthenticationError();
    if (file.status === 429) throw new CloudRateLimitError();
    if (!file.ok) throw new Error(await file.text());
    return file.text();
  }

  /**
   * Returns a list of keys (max 1000) that exist on the storage
   */
  static async list(): Promise<string[]> {
    if (!this.token) this.init();

    const keys: string[] = [];
    (await this.files('*')).forEach((v: {name: string}) => keys.push(v.name));
    return keys;
  }

  /**
   * Returns a list of files that match the passed filename. Pass '*' as filename to return any
   * existing files (max 1000)
   */
  private static async files(filename: string): Promise<{name: string, id: string}[]> {
    if (!this.token) this.init();

    const query = filename !== '*' ? `&q=${encodeURI(`name='${filename}'`)}` : '';
    const response = await fetch(`${this.api.readUrl}?spaces=AppDataFolder&pageSize=1000${query}`, {
      headers: [['Authorization', `Bearer ${this.token}`]],
    });
    const body = JSON.parse(await response.text());
    if (response.status === 401) throw new CloudAuthenticationError();
    if (response.status === 429) throw new CloudRateLimitError();
    if (!response.ok) throw new Error(body);

    return body.files;
  }

  /**
   * Checks whether the specified file already exists. If so, its ID will be returned
   */
  private static async exists(filename: string): Promise<false | string> {
    const files = await this.files(filename);
    if (files.length === 0) return false;
    return files[0].id;
  }

  /**
   * Revokes the access token
   */
  static disconnect() { super.disconnect(); }
}
