import { isArray } from 'util';
import { bugsnagClient } from '../helper/bugsnag';
import CryptoService from '../services/crypto-service';
import { CloudAuthenticationError, CloudInitError, CloudRateLimitError, CloudTransferError, LocalStorageError } from '../types/errors';
import SupportedClouds from '../types/supported-clouds';
import CloudStorage from './cloud';
import CloudDropbox from './cloud-dropbox';
import CloudGoogleDrive from './cloud-googledrive';
import CloudOneDrive from './cloud-onedrive';

/**
 * Manages where data is stored/loaded from
 */
export default abstract class StorageHandler {
  /** Indicates whether init() has been executed before */
  static initialized = false;

  /** Holds the adapter for the currently connected cloud, if any */
  static cloud: CloudStorage | false = false;

  /** List of all saved keys - only used in combination with local storage */
  static index: string[] = [];

  /** Called when the cloud must be forcibly disconnected due to authentication errors */
  static onForcedDisconnect: Function;

  /**
   * Check whether a cloud has been configured and if so, initialize the corresponding adapter
   */
  static init(): void {
    if (this.initialized) return;

    const serializedIndex = localStorage.getItem('storageIndex');
    if (serializedIndex) {
      const parsedIndex = JSON.parse(serializedIndex);
      this.index = isArray(parsedIndex) ? parsedIndex : [];
    }

    const variant = localStorage.getItem('storage');
    if (variant) {
      this.connectCloud(parseInt(variant, 10));
    }

    this.initialized = true;
  }

  /**
   * Writes the passed value to the storage
   */
  static async save(key: string, value: string): Promise<void> {
    if (!this.initialized) this.init();
    bugsnagClient.leaveBreadcrumb('Saving to storage', key);

    // plaintext will be returned, if encryption hasn't been enabled
    const cryptoValue = key === 'encryption' ? value : CryptoService.encrypt(value);

    if (this.cloud) {
      try {
        const { cloud } = this;
        await this.retry(() => cloud.save(key, cryptoValue)); return;
      } catch (e) {
        if (!this.catchDisconnect(e)) throw e;
      }
    }

    try {
      localStorage.setItem(key, cryptoValue);
      this.updateIndex(key);
    } catch (e) {
      // this should most likely only happen if the browsers storage limit has been reached
      throw new LocalStorageError();
    }
  }

  /**
   * Loads a value from the storage
   */
  static async load(key: string): Promise<string | null> {
    if (!this.initialized) this.init();
    bugsnagClient.leaveBreadcrumb('Loading from storage', key);

    try {
      let value: string | null = localStorage.getItem(key);
      if (this.cloud) {
        const { cloud } = this;
        value = await this.retry(() => cloud.load(key));
      }
      return value ? CryptoService.decrypt(value) : null;
    } catch (e) {
      if (!this.catchDisconnect(e)) throw e;
      return null;
    }
  }

  /**
   * Returns a list of keys that exist on the storage. If the storage is a cloud, the amount of keys
   * being returned is limited (typically to 1000-2000 entries, which is more than enough)
   */
  static async list(): Promise<string[]> {
    if (!this.initialized) this.init();

    if (this.cloud) return this.cloud.list();
    return this.index;
  }

  /**
   * Adds the passed key to the storage index, if not already present
   */
  static updateIndex(key: string): void {
    if (this.cloud || this.index.includes(key)) return;
    this.index.push(key);
    localStorage.setItem('storageIndex', JSON.stringify(this.index));
  }

  /**
   * Initializes the specified cloud adapter
   */
  static connectCloud(variant: SupportedClouds): void {
    if (this.cloud) return;

    switch (variant) {
      case SupportedClouds.Dropbox: this.cloud = CloudDropbox; break;
      case SupportedClouds.OneDrive: this.cloud = CloudOneDrive; break;
      case SupportedClouds.GoogleDrive: this.cloud = CloudGoogleDrive; break;
      default: break;
    }

    if (this.cloud) {
      try {
        this.cloud.init();
        localStorage.setItem('storage', variant.toString());
        bugsnagClient.leaveBreadcrumb('Cloud storage connected');
      } catch (e) {
        if (!(e instanceof CloudInitError)) throw e;

        this.cloud = false;
        localStorage.removeItem('storage'); // might already be set, even if try above failed!
      }
    }
  }

  /**
   * Transfers all data from the local storage to the connected cloud storage
   */
  static async transferToCloud(progress?: (value: number) => void): Promise<void> {
    if (!this.cloud || !this.index.length) return;
    if ((await this.cloud.list()).length > 0) throw new CloudTransferError();
    bugsnagClient.leaveBreadcrumb('Transferring local data to cloud storage');

    let done = 0;
    const requests: Promise<void>[] = [];
    this.index.forEach(key => {
      const value = localStorage.getItem(key);
      if (value) {
        requests.push(this.save(key, value)
          .then(() => { if (progress) progress((done += 1) / this.index.length); }));
      }
    });

    await Promise.all(requests); // wait until all data has been transferred

    this.index.forEach(key => localStorage.removeItem(key)); // delete all local data
    localStorage.removeItem('storageIndex');
    this.index = [];
  }

  /**
   * Loads all data from the storage and then saves it again. This is used for when encryption is
   * first enabled, where all unencrypted data is loaded, and then encrypted when it is saved again
   */
  static async rewriteAll(progress?: (value: number) => void): Promise<void> {
    bugsnagClient.leaveBreadcrumb('Rewriting storage data');
    const keys = await this.list();
    const loadRequests: Promise<void>[] = [];
    const saveRequests: Promise<void>[] = [];

    let done = 0;
    const upProgress = () => { if (progress) progress((done += 1) / (keys.length * 2)); };

    keys.forEach(key => {
      if (key === 'encryption') return; // the file containing the checkCipher is ignored

      loadRequests.push(StorageHandler.load(key).then(value => {
        upProgress();
        if (value) saveRequests.push(StorageHandler.save(key, value).then(upProgress));
      }));
    });

    await Promise.all(loadRequests);
    await Promise.all(saveRequests);
  }

  /**
   * Disconnects the currently established cloud connection
   */
  static disconnectCloud(): void {
    if (!this.cloud) return;

    this.cloud.disconnect();
    this.cloud = false;
    localStorage.removeItem('storage');
    bugsnagClient.leaveBreadcrumb('Cloud storage disconnected');
  }

  /**
   * Checks if the passed error is related to authentication problems. If so, the cloud will be
   * disconnected and the onForcedDisconnect callback will be triggered
   */
  private static catchDisconnect(error: any): boolean {
    if (error instanceof CloudAuthenticationError) {
      if (this.onForcedDisconnect instanceof Function) this.disconnectCloud();
      this.onForcedDisconnect();
      return true;
    }

    return false;
  }

  /**
   * In case the request fails due to a CloudRateLimitError, it will be retried up to five times
   */
  private static async retry<T>(request: () => Promise<T>, waitSeconds = 0, count = 0): Promise<T> {
    try {
      await new Promise(resolve => setTimeout(resolve, waitSeconds * 1000));
      return await request();
    } catch (e) {
      if (!(e instanceof CloudRateLimitError) || count >= 5) throw e;
      return this.retry(request, e.retryAfter, count + 1); // retry the failed operation
    }
  }
}
