import { Badge, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Menu, MenuItem } from '@material-ui/core';
import { ArrowBackIos, ArrowForwardIos, Lock, MoreVert, NewReleases, Security } from '@material-ui/icons';
import React, { createElement, memo, useState } from 'react';
import { version } from '../../../../package.json';
import privacyInfo from '../../../privacy';
import CryptoService from '../../../services/crypto-service';
import StorageHandler from '../../../storage/storage-handler';
import PasswordInput from '../../password-input/password-input';
import './menu.scss';

/**
 * Small workaround to fix ArrowBackIos not being horizontally centered like ArrowForwardIos
 * https://github.com/mui-org/material-ui/issues/17425
 */
const ArrowBackIosFixed = () => createElement(ArrowBackIos, { style: { transform: 'translateX(5px)' } });

interface AppMenuProps {
  /** Information about the repository the source code is stored in */
  repository: {url: string, name: string, logoSrc: string};

  /** The year that is currently being displayed */
  displayYear: number;

  /** Whether state changing menu entries should be disabled */
  disabled: boolean;

  /** Called when the user has selected a year to be displayed */
  setDisplayYear: (year: number) => void;

  /** Called when the user enabled encryption and data is currently being encrypted */
  setEncrypting: (isEncrypting: boolean) => void;

  /** Called when the user enabled encryption and the progress is updated */
  setProgress: (value: number) => void;

  /** Will be set when an update is available - calling it will trigger the update */
  update?: () => void;
}


/**
 * Popup menu that contains general app options
 */
const AppMenu: React.FC<AppMenuProps> = (
  { repository, displayYear, disabled, setDisplayYear, setEncrypting, setProgress, update },
) => {
  /** Anchor element for the popup menu */
  const [anchor, setAnchor] = useState<HTMLElement | null>(null);

  /** Whether the password dialog should be displayed or not */
  const [passwordInput, setPasswordInput] = useState<boolean>(false);

  /**
   * Whether the privacy dialog should be displayed or not. We use this workaround instead of a
   * router, because Gitlab Pages don't support proxy/fallback routes and a HashRouter breaks the
   * OAuth response
   */
  const [privacyDialog, setPrivacyDialog] = useState<boolean>(window.location.hash.includes('privacy'));

  /**
   * Initializes the crypto service, if the user entered a password
   */
  const enableEncryption = async (password?: string): Promise<void> => {
    setPasswordInput(false);
    if (!password) return;

    const checkCipher = CryptoService.init(password);
    if (checkCipher) StorageHandler.save('encryption', checkCipher);

    setEncrypting(true);
    await StorageHandler.rewriteAll(setProgress); // encrypt all existing data
    setProgress(0);
    setEncrypting(false);
  };

  /**
   * Closes the privacy dialog and removes the hash from the URL
   */
  const closePrivacyDialog = () => {
    setPrivacyDialog(false);
    window.history.pushState(null, '', window.location.origin + window.location.pathname);
  };

  return (
    <div>
      <IconButton id="app-menu" color="inherit" onClick={event => setAnchor(event.currentTarget)}>
        <Badge invisible={!update} variant="dot" color="secondary">
          <MoreVert />
        </Badge>
      </IconButton>
      <Menu
        className="menu-popup"
        anchorEl={anchor}
        open={!!anchor}
        onClose={() => setAnchor(null)}
        keepMounted
      >
        <li className="year-selection">
          <IconButton
            id="app-menu-prev-year"
            color="inherit"
            disabled={disabled}
            onClick={() => setDisplayYear(displayYear - 1)}
          >
            <ArrowBackIosFixed />
          </IconButton>
          <span>{displayYear}</span>
          <IconButton
            id="app-menu-next-year"
            color="inherit"
            disabled={displayYear >= new Date().getFullYear() || disabled}
            onClick={() => setDisplayYear(displayYear + 1)}
          >
            <ArrowForwardIos />
          </IconButton>
        </li>

        {!CryptoService.isEnabled() && (
          <MenuItem
            disabled={disabled}
            onClick={() => { setAnchor(null); setPasswordInput(true); }}
          >
            <Lock />
            <span>Enable Encryption</span>
          </MenuItem>
        )}

        <MenuItem id="app-menu-privacy" onClick={() => { setAnchor(null); setPrivacyDialog(true); }}>
          <Security />
          <span>Privacy</span>
        </MenuItem>

        <MenuItem onClick={() => window.open(repository.url, '_blank')}>
          <div className="menu-icon"><img src={repository.logoSrc} alt="Tanuki" /></div>
          <span>
            {`Source on ${repository.name}`}
            <span className="sup">{`version ${version}`}</span>
          </span>
        </MenuItem>

        {update && (
          <MenuItem onClick={update}>
            <NewReleases />
            <span>
              {'Update Available'}
              <span className="sup">click to update now</span>
            </span>
          </MenuItem>
        )}
      </Menu>

      <Dialog open={privacyDialog} onClose={closePrivacyDialog}>
        <DialogTitle className="dialog-title">
          <Security />
          <span>Privacy</span>
        </DialogTitle>
        <DialogContent className="privacy-content">{privacyInfo}</DialogContent>
        <DialogActions>
          <Button onClick={closePrivacyDialog} color="primary">Close</Button>
        </DialogActions>
      </Dialog>

      {passwordInput && <PasswordInput onClose={enableEncryption} />}
    </div>
  );
};

export default memo(AppMenu);
