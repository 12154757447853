import queryString from 'query-string';
import { CloudInitError } from '../types/errors';
import SupportedClouds from '../types/supported-clouds';

/**
 * Provides the basic functionality for all cloud adapters
 */
export default abstract class CloudStorage {
  abstract readonly variant: SupportedClouds;
  abstract init: () => void;
  abstract save: (filename: string, value: string) => Promise<void>;
  abstract load: (filename: string) => Promise<string | null>;
  abstract list: () => Promise<string[]>;
  abstract disconnect: () => void;

  /** The access token that is required for API requests */
  static token: string | null = null;

  /** This URL that should be opened when returning from the authentication page */
  static appUrl = window.location.origin + window.location.pathname;

  /**
   * Loads the access token, if possible. Otherwise, authentication will be triggered
   */
  static init(oauth2Url: string): void {
    if (this.token) return;

    // get the token from browser storage, if available
    if (localStorage.getItem('storageToken')) {
      this.token = localStorage.getItem('storageToken');
    }

    // get the token from the URL hash, in case we just returned from the authentication page
    if (!this.token && this.getTokenFromUrl()) {
      this.token = this.getTokenFromUrl();
      window.history.replaceState(null, 'Cloud Connected', this.appUrl); // removes hash from URL
    }

    if (!this.token) {
      // if the flag is already present, we are about to loop - aborting init
      if (localStorage.getItem('storagePending')) {
        console.warn('Failed to init cloud adapter: ', queryString.parse(window.location.hash));
        window.history.replaceState(null, '', this.appUrl); // removes hash from URL
        localStorage.removeItem('storagePending');
        throw new CloudInitError();
      }

      localStorage.setItem('storagePending', 'true');
      window.location.href = oauth2Url; // still no token set, so redirect to authentication page
    } else {
      localStorage.setItem('storageToken', this.token);
      localStorage.removeItem('storagePending');
    }
  }

  /**
   * Returns the access token if present in the URL hash, otherwise NULL
   */
  static getTokenFromUrl(): string | null {
    const query = queryString.parse(window.location.hash);
    return query.access_token ? query.access_token as string : null;
  }

  /**
   * Revokes the access token
   */
  static disconnect(): void {
    if (!this.token) return;

    localStorage.removeItem('storageToken');
    this.token = null;
  }
}
