import { FormHelperText, IconButton, InputAdornment, TextField } from '@material-ui/core';
import { ArrowForward, Lock } from '@material-ui/icons';
import React, { memo, useState } from 'react';
import CryptoService from '../../services/crypto-service';
import './lockscreen.scss';

interface LockscreenProps {
  /** The cipher that was generated by the crypto service after enabling encryption */
  checkCipher: string;

  /** Called when the user enters the correct password */
  onUnlock: () => void;
}


/**
 * Placeholder that is displayed when the diary data is encrypted and the user needs to enter his
 * password
 */
const Lockscreen: React.FC<LockscreenProps> = ({ checkCipher, onUnlock }) => {
  /** The password that the user entered */
  const [password, setPassword] = useState<string>('');

  /** Whether the entered password is correct or not */
  const [error, setError] = useState<boolean>(false);

  /**
   * Check whether the entered password is correct, and if so trigger onUnlock
   */
  const submit = (): void => {
    const init = CryptoService.init(password, checkCipher);
    if (init) { onUnlock(); return; }
    setError(true);
    setPassword('');
  };

  return (
    <div className="lockscreen placeholder">
      <Lock className="icon" />
      <div className="input-wrapper">
        <TextField
          id="lockscreen-input"
          className="input"
          type="password"
          label="Password"
          margin="normal"
          variant="filled"
          value={password}
          onChange={e => { setPassword(e.target.value); setError(false); }}
          onKeyDown={e => { if (e.key === 'Enter') submit(); }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton edge="end" onClick={submit}>
                  <ArrowForward />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        {error && <FormHelperText error>Incorrect password</FormHelperText>}
      </div>
    </div>
  );
};

export default memo(Lockscreen);
