import { Dropbox } from 'dropbox';
import { isString } from 'util';
import { CloudAuthenticationError, CloudRateLimitError } from '../types/errors';
import SupportedClouds from '../types/supported-clouds';
import CloudStorage from './cloud';

/**
 * Cloud adapter for Dropbox - should only be used by the StorageHandler
 */
export default abstract class CloudDropbox extends CloudStorage {
  static readonly variant = SupportedClouds.Dropbox;
  static readonly api = new Dropbox({ clientId: process.env.REACT_APP_CLOUD_DROPBOX, fetch });

  /**
   * Loads the access token, if possible. Otherwise, authentication will be triggered
   */
  static init(): void {
    super.init(this.api.getAuthenticationUrl(this.appUrl));
    if (this.token) this.api.setAccessToken(this.token);
  }

  /**
   * Writes the passed value into a file with the specified filename. If the file already exists,
   * it will be overwritten
   */
  static async save(filename: string, value: string): Promise<void> {
    if (!this.token) this.init();

    try {
      await this.api.filesUpload({
        path: `/${filename}`,
        contents: value,
        mode: { '.tag': 'overwrite' },
      });
    } catch (e) {
      if (e.status && e.status === 401) throw new CloudAuthenticationError();
      if (e.status && e.status === 429) throw new CloudRateLimitError();
      if (e.error) throw new Error(JSON.stringify(e.error));
      throw e;
    }
  }

  /**
   * Loads the content of the specified file
   */
  static async load(filename: string): Promise<string | null> {
    if (!this.token) this.init();

    try {
      const response = await this.api.filesDownload({ path: `/${filename}` });
      const reader = new FileReader();
      return new Promise(resolve => {
        reader.onload = () => resolve(reader.result as string);
        // can't believe the fileBlob is missing from the type-def... wtf?
        reader.readAsText((response as any).fileBlob);
      });
    } catch (e) {
      if (e.status && e.status === 401) throw new CloudAuthenticationError();
      if (e.status && e.status === 429) throw new CloudRateLimitError();
      if (isString(e.error) && e.error.includes('path/not_found')) return null; // ignoring 404
      if (e.error) throw new Error(JSON.stringify(e.error));
      throw e;
    }
  }

  /**
   * Returns a list of keys (max 2000) that exist on the storage
   */
  static async list(): Promise<string[]> {
    if (!this.token) this.init();

    const keys: string[] = [];
    (await this.api.filesListFolder({ path: '', limit: 2000 })).entries.forEach(e => keys.push(e.name));
    return keys;
  }

  /**
   * Revokes the access token
   */
  static disconnect(): void {
    if (!this.token) return;
    this.api.authTokenRevoke().catch(() => {}); // just wanted to say bye; errors are ignored
    super.disconnect();
  }
}
