import classNames from 'classnames';
import React, { Fragment, memo, useState } from 'react';
import styled from 'styled-components';
import { isNumber } from 'util';
import DataService from '../../services/data-service';
import IDay from '../../types/day';
import Moods from '../../types/moods';
import './day.scss';
import DayDetails from './details/details';

interface DayProps {
  /** The date of this day */
  date: Date;

  /** Whether this day is only a filler (e.g. like the 31 of February) */
  isFiller: boolean;

  /** Called whenever the properties of this day have been updated */
  onUpdate: () => void;
}


/**
 * Represents a specific day of the year and is colored based on the defined mood
 */
const Day: React.FC<DayProps> = ({ date, isFiller, onUpdate }) => {
  const [data, setData] = useState<IDay>(DataService.getDay(date) || {});

  /** Controls whether the details dialog is displayed or not */
  const [showDetails, setShowDetails] = useState<boolean>(false);

  /** Whether this day is set in the future */
  const isFuture = date.getTime() > new Date().getTime();

  const classes = classNames({
    day: true,
    filler: isFiller || isFuture,
    blink: data.mood === undefined && date.toDateString() === new Date().toDateString(),
  });

  // set the color of this pixel, depending on the mood
  const Pixel = styled.div`
    background-color: ${isNumber(data.mood) ? Moods[data.mood].color : null};
  `;

  /**
   * Called whenever the details dialog has been closed
   * @param values the new state data; only passed if the user closed the dialog by pressing 'apply'
   */
  const handleDetailsClose = (values?: IDay): void => {
    setShowDetails(false);
    if (!values) return;

    setData(values);
    DataService.setDay(date, values);
    onUpdate();
  };

  return (
    <Fragment>
      <Pixel
        className={classes}
        onClick={!isFiller && !isFuture ? () => setShowDetails(true) : undefined}
      >
        {data.note && !isFiller && <span className="note-indicator"></span>}

        {!date.getMonth() && !(date.getDate() % 5) && !isFiller
          && <span className="label">{date.getDate()}</span>
        }

        {date.getDate() === 1 && !(date.getMonth() % 2) && !isFiller
          && <span className="label">{date.toLocaleString('en-US', { month: 'short' })}</span>
        }
      </Pixel>

      {showDetails && ( // if the details should be displayed
        <DayDetails date={date} values={data} onClose={handleDetailsClose} />
      )}
    </Fragment>
  );
};

export default memo(Day);
