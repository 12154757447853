import React, { memo } from 'react';
import Day from '../day/day';
import './month.scss';

interface MonthProps {
  /** The date of this month (day is ignored) */
  date: Date;

  /** Called whenever the properties of a day have been updated */
  onDayUpdated: () => void;
}


/**
 * Renders all days of a month; always filling up to 31 days
 */
const Month: React.FC<MonthProps> = ({ date, onDayUpdated }) => {
  const [year, month] = [date.getFullYear(), date.getMonth()];
  const daysInMonth = () => new Date(year, month + 1, 0).getDate();

  const renderDays: JSX.Element[] = [];
  for (let i = 1; i < 32; i++) {
    renderDays.push(
      <Day
        key={`${year}-${month}-${i}`}
        date={new Date(year, month, i)}
        onUpdate={onDayUpdated}
        isFiller={i > daysInMonth()}
      />,
    );
  }

  return <div className="month">{renderDays}</div>;
};

export default memo(Month);
